.table-base {
    .ant-table-tbody tr:nth-child(2n) td {
        background-color: $gray-100 !important;
    }
    
    .ant-table-thead {
        tr {
            th {
                background-color: $primary-color;
                color: $white;
            }
        }
    }
    
    .ant-table-tbody {
        tr {
            td {
                background-color: $white;
            }
        }
    }
}

.table-to-print {    
    .ant-table-thead {
        tr {
            th {
                background-color: $dsr-primary-color;
                color: $white;
            }
        }
    }
    
    .ant-table-tbody {
        tr {
            td {
                background-color: $white;
            }
        }
    }
}

.table-to-print-2 {    
    .ant-table-thead {
        tr {
            th {
                background-color: $white;
                color: $black;
                border-top: 1px solid black;
                border-bottom: 1px solid black;
            }

            th:first-child {
                border-left: 1px solid black;
            }

            th:last-child {
                border-right: 1px solid black;
            }
        }
    }
    
    .ant-table-tbody {
        tr {
            td {
                background-color: $white;
            }
        }
    }
}

.table-to-print-2-divider {
	margin: 10px 0 !important;
	border-top: 2px solid black !important;
}
  
.table-to-print-2-divider-dash {
	margin: 10px 0 !important;
	border-top: 2px dashed black !important;
}

.table-to-print-3 {    
	.ant-table-thead {
		tr {
			th {
				background-color: white !important;
				color: black !important;
				border-top: 2px solid black !important;
				border-bottom: 2px solid black !important;
			}
		}
	}

	.ant-table-tbody tr:nth-child(2n) td {
		background-color: white !important;
	}
	
	.ant-table-tbody {
		tr {
			td {
				border-bottom: 2px dashed black !important;
				background-color: white;
				padding: 5px !important
			}
		}
		tr:last-child {
			td {
				border-bottom: none !important;
				background-color: white;
				padding: 5px !important
			}
		}
	}
	.ant-table-summary {
		td {
			border-top: 2px dashed black !important;
			background-color: white;
			padding: 5px !important
		}

	}
}